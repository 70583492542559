<template>
	<div style="display: flex;justify-content: center;padding-top: 20vh;">
		<el-form ref="form" :model="form" label-width="120px" style="width: 400px;">
			<el-form-item label="版本号:">
				<el-input v-model="form.version" placeholder="请输入版本号(例:1220)" :maxlength="4"></el-input>
			</el-form-item>
			<el-form-item label="更新类型:">
				<el-radio-group v-model="form.isForce">
					<el-radio :label="0">热更新</el-radio>
					<el-radio :label="1">强制更新</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item :label="form.isForce == 1 ? '安卓安装包' : '热更新安装包'">
				<el-upload ref="upload" class="file" :action="url" :file-list="file" :auto-upload="false"
					:on-success="change" :limit="1" :headers="{'token':token}" :data="form"
					:name="form.isForce == 1 ? 'apkPackage' : 'hotPackage'">
					<el-button slot="trigger" size="small" type="primary">选取文件</el-button>
				</el-upload>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSubmit">提交</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
	import {
		uploadPackage
	} from '@/api/adminApi.js'
	export default {
		data() {
			return {
				form: { //提交的表单form
					version: '', //版本号
					isForce: 0, //是否为强制更新（1强制更新0热更新）
				},
				token: localStorage.getItem('Token'), //token
				url: this.$url + '/upload/package', //上传文件请求地址
				file: [], //上传的安装包file数组
			}
		},
		methods: {
			async onSubmit() { //提交按钮
				if (!this.form.version) {
					this.$message.error('版本号不能为空');
				} else {
					this.$refs.upload.submit(); //手动上传
				}
			},
			change(res) { //上传文件成功回调
				if (res.status.code == 1) {
					this.$message.success("提交成功");
					this.form = {};
					this.file = [];
				} else {
					this.$message.error(res.status.msg);
				}
			}
		}
	}
</script>

<style scoped>
	.file {
		display: flex;
		justify-content: center;
	}
</style>
